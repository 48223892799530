import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../utils/axiosClient";
import { BiSolidCameraPlus } from "react-icons/bi";
import toast from "react-hot-toast";

const initialInputs = {
  shopName: "",
  shopType: "order",
  email: "",
  phone: "",
  shopAddress: "",
  shopDetail: "",
  operatingHours: "09:00 AM - 06:00 PM",
  shopCategory: "grocery shop",
  operatingDays: {
    M: false,
    T: false,
    W: false,
    Th: false,
    F: false,
    Sa: false,
    Su: false,
  },
  lat: "",
  lng: "",
};

function CreateShop() {
  const [shopImg, setShopImg] = useState("");
  const [inputs, setInputs] = useState(initialInputs);
  const [allShopCategories, setAllShopCategories] = useState([]);
  const [location, setLocation] = useState({ lat: null, lng: null });
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllCategoryShop();
    if (navigator.geolocation) {
      // Request the current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // On success, set the coordinates
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          console.log(err.message);
        },
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const fetchAllCategoryShop = async () => {
    try {
      const response = await axiosClient.get("category/");
      setAllShopCategories(response.result);
    } catch (error) {
      return;
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (fileReader.readyState === fileReader.DONE) {
        setShopImg(fileReader.result);
      }
    };
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (e.target.type === "checkbox") {
      setInputs((prevInputs) => ({
        ...prevInputs,
        operatingDays: {
          ...prevInputs.operatingDays,
          [name]: value,
        },
      }));
    } else {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const validateLatitude = (lat) => {
    const latitude = parseFloat(lat);
    return !isNaN(latitude) && latitude >= -90 && latitude <= 90;
  };

  const validateLongitude = (lng) => {
    const longitude = parseFloat(lng);
    return !isNaN(longitude) && longitude >= -180 && longitude <= 180;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const operatingDays = Object.keys(inputs.operatingDays).filter(
      (day) => inputs.operatingDays[day]
    );

    // Validate latitude and longitude
    if (!validateLatitude(inputs.lat || location.lat)) {
      toast.error("Invalid Latitude");
      return;
    }

    if (!validateLongitude(inputs.lng || location.lng)) {
      toast.error("Invalid Longitude");
      return;
    }

    try {
      const shopData = {
        shopImg: shopImg,
        shopName: inputs.shopName,
        shopDetail: inputs.shopDetail,
        type: inputs.shopType,
        shopAddress: inputs.shopAddress,
        phone: inputs.phone,
        email: inputs.email,
        operatingDays,
        operatingHours: inputs.operatingHours,
        category: inputs.shopCategory,
        lat: inputs.lat || location.lat,
        lng: inputs.lng || location.lng,
      };

      
      // Store in localStorage
      localStorage.setItem("shopData", JSON.stringify(shopData));

      navigate("/payment");
    } catch (error) {
      if (Array.isArray(error)) {
        error.forEach((e) => toast.error(e));
      }
      return;
    }
  };

  const imgLabel =
    "flex justify-center items-center border-2 rounded-xl w-full h-52 text-blue-900 text-2xl bg-blue-100 border-blue-900 border-dashed";
  const input =
    "border-2 border-blue-900 rounded-full px-2 text-blue-500 w-full";
  const label = "text-blue-900 font-semibold";
  const submit =
    "bg-blue-900 rounded-full px-2 text-white w-full cursor-pointer font-semibold mt-5 sm:mt-0 hover:bg-blue-800 hover:text-gray-100";

  return (
    <div className="mb-10 mt-1">
      <p className="bg-blue-900 text-white font-bold text-2xl text-center mb-5 py-1">
        Register Your Business
      </p>
      <div className="flex justify-center">
        <form
          className="w-full sm:w-auto mx-5 border-2 border-blue-900 rounded-xl py-5 px-10 shadow-2xl shadow-blue-200"
          onSubmit={handleSubmit}
        >
          {/* IMAGE */}
          <div className="flex justify-center mb-5">
            {shopImg ? (
              <div className="w-full">
                <img
                  src={shopImg}
                  alt="userImg"
                  className="border-2 border-blue-900 border-dashed rounded-xl w-full h-52"
                />
              </div>
            ) : (
              <div className={`${imgLabel}`}>
                <label htmlFor="inputImg">
                  <BiSolidCameraPlus className="bg-blue-100 cursor-pointer" />
                </label>
                <input
                  className={`${input} hidden`}
                  id="inputImg"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            )}
          </div>

          <div className="sm:flex justify-between items-center">
            <div>
              <p className={`${label}`}>Shop Name:</p>
              <input
                className={`${input}`}
                type="text"
                name="shopName"
                value={inputs.shopName || ""}
                onChange={handleChange}
              />
            </div>
            <div className="my-5 sm:my-0">
              <p className={`${label}`}>Shop Type:</p>
              <select
                className={`${input} sm:w-56`}
                name="shopType"
                value={inputs.shopType || ""}
                onChange={handleChange}
              >
                <option value="order">Order</option>
                <option value="appointment">Appointment</option>
              </select>
            </div>
          </div>

          <div className="sm:flex gap-20 items-center my-5">
            <div>
              <p className={`${label}`}>Email:</p>
              <input
                className={`${input}`}
                type="email"
                name="email"
                value={inputs.email || ""}
                onChange={handleChange}
              />
            </div>
            <div className="my-5 sm:my-0">
              <p className={`${label}`}>Phone:</p>
              <input
                className={`${input}`}
                type="text"
                name="phone"
                value={inputs.phone || ""}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="my-5">
            <p className={`${label}`}>Shop Address:</p>
            <input
              type="text"
              className={`${input}`}
              name="shopAddress"
              value={inputs.shopAddress || ""}
              onChange={handleChange}
            />
          </div>

          <div className="my-5">
            <p className={`${label}`}>Shop Detail:</p>
            <textarea
              name="shopDetail"
              value={inputs.shopDetail || ""}
              onChange={handleChange}
              className="border-2 border-blue-900 w-full rounded-lg h-36 text-blue-500 p-2 "
            />
          </div>

          <div className="sm:flex justify-between my-5">
            <div className="sm:w-52 ">
              <p className={`${label}`}>Shop Variety</p>
              <select
                className={`${input} `}
                name="shopCategory"
                value={inputs.shopCategory || ""}
                onChange={handleChange}
              >
                {allShopCategories.map((data) => (
                  <option key={data._id} value={data.categoryName}>
                    {data.categoryName}
                  </option>
                ))}
              </select>
            </div>

            <div className="my-5 sm:my-0">
              <p className={`${label}`}>Operating Hours:</p>
              <select
                className={`${input}`}
                name="operatingHours"
                value={inputs.operatingHours || ""}
                onChange={handleChange}
              >
                <option value="09:00 AM - 06:00 PM">09:00 AM - 06:00 PM</option>
                <option value="10:00 AM - 07:00 PM">10:00 AM - 07:00 PM</option>
                <option value="10:00 AM - 05:00 PM">10:00 AM - 05:00 PM</option>
              </select>
            </div>
          </div>

          <div className="my-5">
            <p className={`${label} mb-4`}>Location:</p>
            <div className="sm:flex justify-between">
              <div>
                <p className={`${label}`}>Longitude:</p>
                <input
                  type="text"
                  className={`${input}`}
                  name="lng"
                  value={inputs.lng || location.lng}
                  onChange={handleChange}
                />
              </div>
              <div className="my-5 sm:my-0">
                <p className={`${label}`}>Latitude:</p>
                <input
                  type="text"
                  className={`${input}`}
                  name="lat"
                  value={inputs.lat || location.lat}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <p className="text-xs">
            <span className="text-red-500">NOTE:</span> Latitude and Longitude
            should be accurate for your shop's location.
          </p>

          <div className="my-5">
            <p className={`${label}`}>Opening Days:</p>
            <div className="flex gap-2 text-blue-500">
              {["M", "T", "W", "Th", "F", "Sa", "Su"].map((day) => (
                <div key={day}>
                  <input
                    type="checkbox"
                    name={day}
                    checked={inputs.operatingDays[day]}
                    onChange={handleChange}
                  />{" "}
                  {day}
                </div>
              ))}
            </div>
          </div>

          <input
            type="submit"
            value="Save & Continue"
            className={`${submit}`}
          />
        </form>
      </div>
    </div>
  );
}

export default CreateShop;
