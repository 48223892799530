import React from "react";
import { FaFacebookF, FaXTwitter } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="text-center sm:text-left sm:flex justify-around py-5 bg-white border-t border-blue-900">
      <div>
        <p className="font-medium text-base md:text-lg">Get in Touch</p>
        <div className="text-xs space-y-2 mt-3">
          <p>
            <a href="+917302689709" className="hover:underline">
              +91 730 2689 709
            </a>
          </p>
          <p>
            <a href="tel:+919149101081" className="hover:underline">
              +91 914 9101 081
            </a>
          </p>
          <p>
            <a
              href="mailto:dhruvgarg@bookandbuy.in?subject=Hello%20CEO"
              className="hover:underline"
            >
              dhruvgarg@bookandbuy.in
            </a>
          </p>
          <p className="sm:w-32 mx-20 sm:mx-0">
            House No. 1735, New Pannapuri, Hapur, Uttar Pradesh, 245101
          </p>
        </div>
      </div>
      <div>
        <p className="font-medium text-base md:text-lg mt-5 sm:mt-0">
          Quick Links
        </p>
        <div className="flex flex-col gap-2 text-xs mt-3">
          <Link
            to="/faqs"
            className="cursor-pointer hover:underline hover:text-blue-900"
          >
            FAQ's
          </Link>
          <Link
            to="/terms&Conditions"
            className="cursor-pointer hover:underline hover:text-blue-900"
          >
            Terms & Conditions
          </Link>
          <Link
            to="/cancellation&Refund"
            className="cursor-pointer hover:underline hover:text-blue-900"
          >
            Cancellation & Refund
          </Link>
          <Link
            to="/privacyPolicy"
            className="cursor-pointer hover:underline hover:text-blue-900"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
      <div>
        <p className="font-medium text-base md:text-lg mt-5 sm:mt-0">
          Social Media
        </p>
        <div className="flex justify-center gap-4 mt-3 text-xs">
          <a
            href="https://www.facebook.com/share/Upe8q2FbNpRQQD1u/?mibextid=qi2Omg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://x.com/bookndbuy?t=skNRBla7_dlcQ0mnYgm-Ng&s=08"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaXTwitter />
          </a>
          <a
            href="https://www.instagram.com/bookandbuy.in/?igsh=dGN2MDUwMHFzdDhu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoInstagram />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
