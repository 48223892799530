import React from "react";

function TermsAndConditions() {
  const termsAndConditions = [
    "As a user of this website, you may be asked to register with us and provide private information. Your are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of you identifying information.",
    "You have to register only with your verified mobile number or email id.",
    "If you do any irrelevant activity with website or its data then we will delist your shop and mark your account permanently banned.",
    "As a shopkeeper if you sell any damage/shortage product then you have to give refund to the customer.",
    "After book an appointment if you will not be able to go on time or within 5-10 minutes then shopkeeper can attend another customer.",
    "By using this website, you certify that you have read and reviewed this agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this agreement, you are advised to stop using the website accordingly. Off shop only grants use and access of this website, its products, and its services to those who have accepted its terms.",
  ];

  return (
    <div className="flex flex-col items-center mt-0 sm:-mt-5 md:mt-0 bg-white space-y-2 py-5">
      <p className="text-lg sm:text-xl font-semibold">
        Terms & Conditions (T&C)
      </p>
      <p className="text-sm sm:text-base font-medium px-5 sm:px-0">
        Please read below Terms & Conditions carefully before using this
        website:-
      </p>
      <ul className="space-y-5">
        {termsAndConditions.map((terms, idx) => (
          <li
            key={idx}
            className="list-disc mx-10 sm:mx-20 md:mx-30 lg:mx-40  text-xs sm:text-sm"
          >
            {terms}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TermsAndConditions;
