import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function Sidebar({ onClose }) {
  const [isClosing, setIsClosing] = useState(false);
  const navigate = useNavigate();

  const handleCloseClick = () => {
    setIsClosing(true);
    setTimeout(onClose, 400); // Delay onClose to allow animation to finish
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-start">
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 ${
          isClosing ? "opacity-0" : "opacity-50"
        }`}
        onClick={handleCloseClick}
      ></div>
      <div
        className={`relative h-screen w-80 bg-white text-sm md:text-base lg:text-lg transition-transform duration-300 ${
          isClosing ? "animate-sidebarSlideOut" : "animate-sidebarSlideIn"
        }`}
      >
        <AiOutlineClose
          className="absolute right-0 top-2 text-lg sm:text-2xl font-semibold cursor-pointer"
          onClick={handleCloseClick}
        />
        <div className="flex flex-col mt-10 ">
          <p
            className="cursor-pointer border-y-2 border-black py-1 px-4"
            onClick={() => navigate("/aboutUs")}
          >
            About Us
          </p>
          <p
            className="cursor-pointer py-1 px-4"
            onClick={() => navigate("/allOrders")}
          >
            All Orders
          </p>
          <p
            className="cursor-pointer border-y-2 border-black py-1 px-4"
            onClick={() => navigate("/allAppointments")}
          >
            All Appointments
          </p>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
