import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiQuestionAnswerLine } from "react-icons/ri";

function FAQs() {
  const [openIndex, setOpenIndex] = useState(null);

  const faq = [
    {
      icon: <RiQuestionAnswerLine className="text-2xl" />,
      question: "How you solve a global problem?",
      dropdown: <RiArrowDropDownLine />,
      answer:
        "We streamline the process of making orders and booking appointments, allowing customers to skip long lines and wait times",
    },
    {
      icon: <RiQuestionAnswerLine className="text-2xl" />,
      question: "How can I get an appointment/place an order?",
      dropdown: <RiArrowDropDownLine />,
      answer:
        "Simply choose your nearest shop and click on the book appointment button add suitable date or add products in cart you want to buy",
    },
    {
      icon: <RiQuestionAnswerLine className="text-2xl" />,
      question: "Is it necessary to register as a shopkeeper on your website?",
      dropdown: <RiArrowDropDownLine />,
      answer:
        "No, only if you own a shop or want to register your shop with us, else you can use our website as a customer",
    },
    {
      icon: <RiQuestionAnswerLine className="text-2xl" />,
      question: "Is there any age limit to sign in?",
      dropdown: <RiArrowDropDownLine />,
      answer: "No, there is no age limitations to sign in on the website",
    },
    {
      icon: <RiQuestionAnswerLine className="text-2xl" />,
      question: "How can I cancel my order/appointment?",
      dropdown: <RiArrowDropDownLine />,
      answer:
        "Simply, when you successfully place your order or appointment, you'll see a cancel button. Click on it, and your order or appointment will be canceled",
    },
    {
      icon: <RiQuestionAnswerLine className="text-2xl" />,
      question: "How can I register my shop?",
      dropdown: <RiArrowDropDownLine />,
      answer:
        'If you want to register your shop first of all go to the top right corner of navbar, hover/click over you profile picture you will see an option named "Become an offShoper" click on it and fill required details',
    },
    {
      icon: <RiQuestionAnswerLine className="text-2xl" />,
      question: "If I get any wrong/damage product how can I complaint?",
      dropdown: <RiArrowDropDownLine />,
      answer:
        "Contact on customer support of the particular shop or mail on the shop email or raise you complaint on our email we will resolve it within 4-5 working days",
    },
    {
      icon: <RiQuestionAnswerLine className="text-2xl" />,
      question: "How can I edit and manage my shop?",
      dropdown: <RiArrowDropDownLine />,
      answer:
        'Go to the top right corner of the navbar hover/click on your profile picture, click on the "Go To Dashboard" where you will get all the options to update your shop details, facilities and products',
    },
    {
      icon: <RiQuestionAnswerLine className="text-2xl" />,
      question: "How can I delete my offShop account?",
      dropdown: <RiArrowDropDownLine />,
      answer:
        "To delete your offShop account you need to contact to the owner of this website whose contacts are available at the bottom of every page",
    },
  ];

  const handleToggle = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close the currently open item
    } else {
      setOpenIndex(index); // Open the clicked item
    }
  };

  return (
    <div className="px-10 sm:px-20 md:px-30 lg:px-40 bg-white mt-0 sm:-mt-5 md:mt-0 py-5">
      <p className="text-xl font-semibold text-center">
        Frequently Asked Questions (FAQ's)
      </p>
      <div className="divide-y text-xs sm:text-sm font-medium pt-5">
        {faq.map((data, idx) => (
          <div className="py-4" key={idx}>
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => handleToggle(idx)}
            >
              <p className="flex items-center gap-3">
                {data.icon} {data.question}
              </p>
              <p
                className={`transform transition-transform duration-300 text-2xl ${
                  openIndex === idx ? "rotate-180" : ""
                }`}
              >
                {data.dropdown}
              </p>
            </div>
            {/* ANSWER */}
            {openIndex === idx && (
              <p className={`px-2 font-normal text-xs sm:text-sm `}>
                {data.answer.split(". ").map((sentence, index) => (
                  <p className="my-2 ml-7" key={index}>
                    {sentence}.
                  </p>
                ))}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQs;
