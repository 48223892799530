import React, { useState } from "react";
import { axiosClient } from "../utils/axiosClient";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getShopCustomer } from "../redux/slices/shopsSlice";

const RatingModal = ({ onClose, shopId }) => {
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      const response = await axiosClient.post(`/rate/${shopId}`, {
        rating,
      });
      toast.success(response.result);
      dispatch(getShopCustomer(shopId));
    } catch (e) {
      toast.error(e);
    } finally {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-md text-center w-full max-w-md mx-4 sm:mx-auto">
        <h2 className="mb-4 text-2xl font-bold text-gray-800">Rate</h2>
        <div className="mb-4">
          <p className="mb-2 text-lg">Please rate your experience:</p>
          <input
            type="number"
            min="1"
            max="5"
            value={rating}
            onChange={(e) => setRating(e.target.value)}
            className="border border-gray-300 p-2 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex justify-between space-x-4 mt-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-150 ease-in-out"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RatingModal;
