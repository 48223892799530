import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { axiosClient } from "../../utils/axiosClient";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getShopAdmin } from "../../redux/slices/shopsSlice";
import { BiSolidCameraPlus } from "react-icons/bi";

const initialInputs = {
  shopImg: "",
  shopName: "",
  shopOwner: "",
  email: "",
  phone: "",
  shopAddress: "",
  shopDetail: "",
  lat: "",
  lng: "",
};

function UpdateDetail({ data, onClose }) {
  const updateRef = useRef();
  const [isClosing, setIsClosing] = useState(false);
  const [inputs, setInputs] = useState(initialInputs);
  const [location, setLocation] = useState({ lat: null, lng: null });
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.shopsSlice.shopId);

  useEffect(() => {
    function handleClickOutside(event) {
      if (updateRef.current && !updateRef.current.contains(event.target)) {
        setIsClosing(true);
        setTimeout(onClose, 400);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (navigator.geolocation) {
      // Request the current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // On success, set the coordinates
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          console.log(err.message);
        },
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }

    const previousData = {
      shopImg: "",
      shopName: data.shopName || "",
      email: data.email || "",
      phone: data.phone || "",
      shopAddress: data.shopAddress || "",
      shopDetail: data.shopDetail || "",
      lat:
        data.location &&
        data.location.coordinates &&
        data.location.coordinates[1] != null
          ? data.location.coordinates[1]
          : "",
      lng:
        data.location &&
        data.location.coordinates &&
        data.location.coordinates[0] != null
          ? data.location.coordinates[0]
          : "",
    };

    setInputs(previousData);
  }, [data]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      if (fileReader.readyState === fileReader.DONE) {
        setInputs((prev) => ({ ...prev, shopImg: fileReader.result }));
      }
    };
    fileReader.onerror = () => {
      toast.error("Error reading file");
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleCloseClick = () => {
    setIsClosing(true);
    setTimeout(onClose, 400);
  };

  const setCurrentLocation = () => {
    setInputs((prev) => ({
      ...prev,
      lat: location.lat,
      lng: location.lng,
    }));
  };

  const validateLatitude = (lat) => {
    const latitude = parseFloat(lat);
    return !isNaN(latitude) && latitude >= -90 && latitude <= 90;
  };

  const validateLongitude = (lng) => {
    const longitude = parseFloat(lng);
    return !isNaN(longitude) && longitude >= -180 && longitude <= 180;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate latitude and longitude
    if (!validateLatitude(inputs.lat || location.lat)) {
      toast.error("Invalid Latitude");
      return;
    }

    if (!validateLongitude(inputs.lng || location.lng)) {
      toast.error("Invalid Longitude");
      return;
    }

    try {
      const response = await axiosClient.put(
        `updateShop/shop/${shopId}`,
        inputs
      );
      toast.success(response.result);
      dispatch(getShopAdmin(shopId));
      onClose();
    } catch (error) {
      return;
    }
  };

  return (
    <div
      ref={updateRef}
      className={`fixed inset-4 sm:inset-10 md:inset-20 lg:inset-30 z-50 text-blue-900 border border-dashed border-blue-900 rounded-lg bg-white transition-all duration-300 ${
        isClosing ? "animate-fadeOut" : "animate-fadeIn"
      }  `}
    >
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-xl font-semibold">Update Shop Details</h2>
        <button onClick={handleCloseClick}>
          <AiOutlineClose size={24} />
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-6 p-4 overflow-y-auto max-h-96 sm:max-h-80"
      >
        <div>
          <div className="flex justify-center">
            {inputs.shopImg ? (
              <img
                src={inputs.shopImg}
                alt="shopImg"
                className="border border-dashed border-blue-900 rounded sm:rounded-lg size-44 sm:w-96 sm:h-56"
              />
            ) : (
              <img
                src={data.imgUrl.url}
                alt="shopImg"
                className="border border-dashed border-blue-900 rounded sm:rounded-lg size-44 sm:w-96 sm:h-56"
              />
            )}
          </div>
          <div className="mt-3">
            <label htmlFor="inputImg">
              <p className="flex justify-center cursor-pointer border rounded border-blue-900 bg-blue-500 text-white text-xl px-2 py-[0.5px] w-10">
                <BiSolidCameraPlus />
              </p>
            </label>
            <input
              id="inputImg"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleImageChange}
            />
          </div>
        </div>
        <input
          type="text"
          name="shopName"
          value={inputs.shopName}
          placeholder="Shop Name"
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <input
          type="text"
          name="phone"
          value={inputs.phone}
          placeholder="Phone"
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <input
          type="text"
          name="shopDetail"
          value={inputs.shopDetail}
          placeholder="Detail"
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <input
          type="text"
          name="shopAddress"
          value={inputs.shopAddress}
          placeholder="Address"
          onChange={handleChange}
          className="p-2 border rounded"
        />

        <div className="sm:flex justify-between">
          <div>
            <input
              type="text"
              className="p-2 border rounded w-full"
              name="lng"
              value={inputs.lng}
              onChange={handleChange}
              placeholder="Longitude"
            />
          </div>
          <div className="mt-5 sm:my-0">
            <input
              type="text"
              className="p-2 border rounded w-full"
              name="lat"
              value={inputs.lat}
              onChange={handleChange}
              placeholder="Latitude"
            />
          </div>
        </div>

        <button
          type="button"
          className="border rounded p-2"
          onClick={setCurrentLocation}
        >
          Set Current Location
        </button>

        <p className="text-xs">
          <span className="text-red-500">NOTE:</span> Longitude and Latitude
          should be accurate for your shop's location.
        </p>
        <input
          type="email"
          name="email"
          value={inputs.email}
          placeholder="Email"
          onChange={handleChange}
          className="p-2 border rounded"
        />
        <button type="submit" className="p-2 bg-blue-500 text-white rounded">
          Update
        </button>
      </form>
    </div>
  );
}

export default UpdateDetail;
