import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { PiMaskSad } from "react-icons/pi";
import { TbCalendarCancel } from "react-icons/tb";
import { axiosClient } from "../utils/axiosClient";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getAllAppointments } from "../redux/slices/shopsSlice";
import ConfirmationModal from "./ConfirmationModal";
import { useNavigate } from "react-router-dom";

function CreateAppointment({ onClose }) {
  const appointmentRef = useRef();
  const [isClosing, setIsClosing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState("");
  const [fromTime, setFromTime] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shopId = useSelector((state) => state.shopsSlice.shopId);
  const allAppointments = useSelector(
    (state) => state?.shopsSlice?.appointmentsData
  );
  const myProfile = useSelector((state) => state?.appConfigReducer?.myProfile);

  const handleCloseModal = () => {
    setShowModal(false);
    return;
  };
  const handleConfirm = () => {
    navigate("/updateProfile");
    handleCloseModal();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        appointmentRef.current &&
        !appointmentRef.current.contains(event.target)
      ) {
        setIsClosing(true);
        setTimeout(onClose, 400);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleCloseClick = () => {
    setIsClosing(true);
    setTimeout(onClose, 400);
  };

  const handleBookAppointment = async () => {
    try {
      if (myProfile?.phone) {
        const response = await axiosClient.post(`appointment/${shopId}`, {
          date,
          fromTime,
        });
        toast.success(response.result);
        dispatch(getAllAppointments());
      } else {
        setShowModal(true);
        return;
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    try {
      dispatch(getAllAppointments());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  const cancelAppointment = async (id) => {
    try {
      const response = await axiosClient.delete(`appointment/${id}`);
      toast.success(response.result);
      dispatch(getAllAppointments());
    } catch (error) {
      return;
    }
  };

  return (
    <div
      ref={appointmentRef}
      className={`fixed inset-4 sm:inset-10 md:inset-20 lg:inset-30 z-50 text-blue-900 border border-dashed border-blue-900 rounded-lg bg-white transition-all duration-300 ${
        isClosing ? "animate-fadeOut" : "animate-fadeIn"
      }`}
    >
      <div className="flex flex-col gap-6 rounded-lg bg-white">
        <div className="flex items-center justify-between font-bold text-lg border-b border-blue-900 px-4 rounded-t-lg bg-blue-900 text-white">
          <p className="bg-blue-900">Choose Slot</p>
          <AiOutlineClose
            className="cursor-pointer bg-blue-900"
            onClick={handleCloseClick}
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-6 sm:gap-0 sm:justify-around sm:items-center bg-white">
          <div className="flex flex-col gap-1 mx-4 sm:mx-0 bg-white">
            <label htmlFor="date" className="font-semibold bg-white">
              Date
            </label>
            <input
              type="date"
              name="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="border border-blue-900 rounded bg-white"
            />
          </div>
          <div className="flex flex-col gap-1 mx-4 sm:mx-0 bg-white">
            <label htmlFor="fromTime" className="font-semibold bg-white">
              Time
            </label>
            <input
              type="time"
              name="fromTime"
              id="fromTime"
              value={fromTime}
              onChange={(e) => setFromTime(e.target.value)}
              className="border border-blue-900 rounded bg-white"
            />
          </div>
        </div>
        <button
          className="font-bold border border-blue-900 rounded mx-4 sm:mx-48"
          onClick={handleBookAppointment}
        >
          Book Appointment
        </button>

        <p className="bg-blue-900 text-white text-lg font-semibold text-center">
          Your Appointments
        </p>

        {allAppointments?.length !== 0 ? (
          <div className="bg-white flex justify-center">
            <div className="w-full mx-4 sm:mx-16 md:mx-28 lg:mx-48">
              <div className="overflow-x-auto">
                <table className="border-collapse w-full">
                  <thead>
                    <tr className="flex">
                      <th className="p-1 bg-blue-900 text-white flex-1">
                        Shop Name
                      </th>
                      <th className="p-1 bg-blue-900 text-white flex-1">
                        Date
                      </th>
                      <th className="p-1 bg-blue-900 text-white flex-1">
                        Time
                      </th>
                      <th className="p-1 bg-blue-900 text-white flex-1">
                        Cancel
                      </th>
                    </tr>
                  </thead>
                  <tbody className="block h-32 bg-white overflow-y-auto hideScrollbar">
                    {allAppointments?.map((data, idx) => {
                      const date = new Date(data.date);
                      const month = (date.getMonth() + 1)
                        .toString()
                        .padStart(2, "0");
                      const day = date.getDate().toString().padStart(2, "0");
                      const year = date.getFullYear();
                      const formattedDate = `${day}/${month}/${year}`;

                      return (
                        <tr
                          key={idx}
                          className="flex items-center text-center w-full border border-blue-900 bg-white"
                        >
                          <td className="p-1 flex-1">{data.shopName}</td>
                          <td className="p-1 flex-1">{formattedDate}</td>
                          <td className="p-1 flex-1">
                            {data.fromTime}
                          </td>
                          <td
                            className="p-1 flex-1"
                            onClick={() => cancelAppointment(data._id)}
                          >
                            <TbCalendarCancel className="mx-auto text-xl cursor-pointer" />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2 bg-white">
            <p className="bg-white text-center font-semibold">
              No Appointments Yet
            </p>
            <p className="bg-white flex justify-center text-5xl">
              <PiMaskSad className="bg-white " />
            </p>
          </div>
        )}
      </div>
      <ConfirmationModal
        show={showModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        message="Please update your phone number!"
        action="Update"
      />
    </div>
  );
}

export default CreateAppointment;
