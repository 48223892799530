import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TbShoppingBag, TbUserCircle } from "react-icons/tb";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdLogOut } from "react-icons/io";
import { axiosClient } from "../utils/axiosClient";
import { useDispatch, useSelector } from "react-redux";
import { KEY_ACCESS_TOKEN, removeItem } from "../utils/localStorageManager";
import BookAndBuy_Logo from "../assets/BookAndBuyLogo.jpg";
import Cart from "./Cart";
import Sidebar from "./Sidebar";
import Tooltip from "./Tooltip";
import toast from "react-hot-toast";
import { getMyInfo } from "../redux/slices/appConfigSlice";
import ConfirmationModal from "./ConfirmationModal";

function Navbar() {
  const [openCart, setOpenCart] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myProfile = useSelector((state) => state?.appConfigReducer?.myProfile);
  const cart = useSelector((state) => state?.cartSlice?.cart);

  var totalItems = 0;
  cart.forEach((item) => (totalItems += item.quantity));

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const logOut = async () => {
    try {
      await axiosClient.get("jwtAuth/logout");
      removeItem(KEY_ACCESS_TOKEN);
      removeItem("refreshToken");
      dispatch(getMyInfo());
      navigate("/login");
    } catch (error) {
      toast.error(error?.toString());
    }
  };

  const routes = [
    { path: "/aboutus", name: "About Us" },
    { path: "/allOrders", name: "Orders" },
    { path: "/allAppointments", name: "Appointments" },
  ];

  const renderProfileMenu = () => (
    <div
      className="relative"
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
    >
      {myProfile?.imgUrl?.url ? (
        <img
          src={myProfile?.imgUrl?.url}
          alt="img"
          className="size-6 md:size-8 rounded-full border border-blue-900 cursor-pointer"
        />
      ) : (
        <TbUserCircle className="text-2xl md:text-3xl  cursor-pointer" />
      )}
      {dropdownOpen && (
        <>
          {myProfile?.role === "shopkeeper" ? (
            <div className="absolute right-0 w-48 bg-white border rounded shadow-lg z-50">
              {[
                "/signup",
                "/login",
                "/updateProfile",
                `/shop-admin/${myProfile.shopId}`,
              ].map((path, idx) => (
                <p
                  key={idx}
                  className="block px-4 py-2 text-blue-900 hover:text-white hover:bg-blue-900 cursor-pointer"
                  onClick={() => navigate(path)}
                >
                  {path === "/signup"
                    ? "Sign Up"
                    : path === "/login"
                    ? "Log In"
                    : path === "/updateProfile"
                    ? "Update Profile"
                    : "Go To Dashboard"}
                </p>
              ))}
            </div>
          ) : (
            <div className="absolute right-0 w-48 bg-white border rounded shadow-lg z-50">
              {["/signup", "/login", "/updateProfile", "/create-shop"].map(
                (path, idx) => (
                  <p
                    key={idx}
                    className="block px-4 py-2 text-blue-900 hover:text-white hover:bg-blue-900 cursor-pointer"
                    onClick={() => navigate(path)}
                  >
                    {path === "/signup"
                      ? "Sign Up"
                      : path === "/login"
                      ? "Log In"
                      : path === "/updateProfile"
                      ? "Update Profile"
                      : "Register Your Shop"}
                  </p>
                )
              )}
            </div>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className="z-50 fixed w-screen bg-white border-b border-blue-900">
      <div className="sm:flex items-center px-5 text-black font-semibold">
        {/* Left Part */}
        <div className="flex-1 flex items-center justify-between">
          <img
            src={BookAndBuy_Logo}
            alt="Logo"
            className="size-11 md:size-14 p-1 rounded-full cursor-pointer"
            onClick={() => navigate("/")}
          />

          {/* Mobile View Icons */}
          <div className="flex items-center gap-3 sm:hidden">
            {myProfile !== null ? (
              <Tooltip tooltipText="LogOut">
                <IoMdLogOut
                  className="text-xl mt-2 cursor-pointer"
                  onClick={() => setShowModal(true)}
                />
              </Tooltip>
            ) : (
              <Tooltip tooltipText="SignUp">
                <IoMdLogOut
                  className="text-lg mt-1 cursor-pointer"
                  onClick={() => navigate("/signup")}
                />
              </Tooltip>
            )}
            {renderProfileMenu()}

            <div className="relative" onClick={() => setOpenCart(!openCart)}>
              <TbShoppingBag className="text-xl cursor-pointer" />
              {totalItems > 0 && (
                <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold rounded-full px-1">
                  {totalItems}
                </span>
              )}
            </div>
            <RxHamburgerMenu
              className="text-xl cursor-pointer"
              onClick={() => setOpenSidebar(!openSidebar)}
            />
          </div>
        </div>

        {/* Center Part */}
        <div className="flex-1 hidden sm:flex items-center justify-around sm:text-xs md:text-sm lg:text-base">
          {routes.map((route, idx) => (
            <p
              key={idx}
              className="cursor-pointer"
              onClick={() => navigate(route.path)}
            >
              {route.name}
            </p>
          ))}
        </div>

        {/* Right Part */}
        <div className="flex-1 hidden sm:flex items-center justify-end gap-3">
          {myProfile !== null ? (
            <Tooltip tooltipText="LogOut">
              <IoMdLogOut
                className="mt-2 text-lg md:text-xl lg:text-2xl cursor-pointer"
                onClick={() => setShowModal(true)}
              />
            </Tooltip>
          ) : (
            <Tooltip tooltipText="SignUp">
              <IoMdLogOut
                className="mt-2 text-lg md:text-xl lg:text-2xl cursor-pointer"
                onClick={() => navigate("/signup")}
              />
            </Tooltip>
          )}
          {renderProfileMenu()}
          <div className="relative" onClick={() => setOpenCart(!openCart)}>
            <TbShoppingBag className="text-lg md:text-xl lg:text-2xl cursor-pointer" />
            {totalItems > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold rounded-full px-1">
                {totalItems}
              </span>
            )}
          </div>
        </div>
      </div>

      <ConfirmationModal
        show={showModal}
        onClose={handleCloseModal}
        onConfirm={logOut}
        message="Do you want to logout?"
        action="Log Out"
      />

      {openCart && <Cart onClose={() => setOpenCart(false)} />}
      {openSidebar && <Sidebar onClose={() => setOpenSidebar(false)} />}
    </div>
  );
}

export default Navbar;
